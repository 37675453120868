import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import {withSnackbar} from 'notistack';
 
import { NavLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogConnectToWallet from './DialogConnectToWallet';
import DialogAddressInfo from './DialogAddressInfo';




const styles = theme => ({
    root: {
        background: 'transparent',
    },
    appbar: {
        background: 'transparent',
    },
    mainToolbar: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    //   background: 'transparent'
      background: theme.palette.primary.main,
    },
    navlink: {
        // background:theme.palette.primary.light, 
        color: theme.palette.secondary.main,
        'text-decoration': 'none',
    },
    navText: {
        "font-family": "Permanent Marker"
    }
  });

 
class Navigation extends React.Component {

    state = {
        open: false,
        anchorEl: null,
    }

    handleToggle = (event) => {
        this.setState({open: !this.state.open, anchorEl: event.currentTarget})
      };
    
      handleClickAway = () => {
        this.setState({open: false})
      };

    render() {
        const {
            classes, 
            onClick, 
            loggedIn, 
            account, 
            deployedNetwork,
            detectedNetwork,
            deployedNetworkName,
            detectedNetworkName

        } = this.props;
        return (
            <div className={classes.root}>
                <AppBar className={classes.appbar} position="fixed" elevation={0} square={true}>
                    <Toolbar className={classes.mainToolbar} elevation={0} square='true' variant='dense'>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography className={classes.navText} color='secondary' align='left' variant='h5'>
                                    <NavLink className={classes.navlink} to="/">{'MZKZ || Mint Portal '}</NavLink>
                                    {/* {this.props.appVersion} */}
                                </Typography>
                            </Grid>

                            <Grid item>

                            <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                            spacing={5}
                            >

                            <Grid item>
                                <NavLink className={classes.navlink} to={this.props.featuredMint}>
                                    <Button className={classes.navText} color='secondary' variant='text'>
                                        {'Featured Mint'}
                                    </Button>
                                </NavLink>
                            </Grid>

                            <Grid item>
                                <NavLink className={classes.navlink} to="/artworks">
                                    <Button className={classes.navText} color='secondary' variant='text'>
                                        {'Artworks'}
                                    </Button>
                                </NavLink>
                            
                            </Grid>

                            <Grid item>
                                <NavLink className={classes.navlink} to="/about">
                                    <Button className={classes.navText} color='secondary' variant='text'>
                                        {'About'}
                                    </Button>
                                </NavLink>
                            </Grid>

                            </Grid>
                            </Grid>

                            <Grid item>
                                { this.props.loggedIn && this.props.account !== null
                                    ? (
                                        <DialogAddressInfo 
                                            onClick={onClick} 
                                            loggedIn={loggedIn} 
                                            account={account} 
                                            deployedNetwork={deployedNetwork}
                                            detectedNetwork={detectedNetwork}
                                            deployedNetworkName={deployedNetworkName}
                                            detectedNetworkName={detectedNetworkName}
                                        /> 
                                    )
                                        
                                    : <DialogConnectToWallet onClick={onClick} loggedIn={loggedIn} account={account} variant="outlined"/>
                                }
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Toolbar/>
                
            </div>
        )
    }
}
 
export default withStyles(styles)(withSnackbar(withTheme(Navigation)));