import React, {Component} from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {withSnackbar} from 'notistack';
import { withTheme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import Web3 from "web3";
import { ethers } from "ethers";
import './App.css';
import Navigation from './Components/Navigation';

import ABI from './ABI/subCollectionABI.json'
import presaleABI from './ABI/presaleABI.json'
import baseABI from './ABI/baseContractABI.json'
import carvestABI from './ABI/carvestTimeABI.json'

import Artworks from './Pages/Artworks';
import Home from './Pages/Home';
import About from './Pages/About';
import AssetPage from './Pages/AssetPage';

import data from "./MetaData/allData.json"
import StickyFooter from "./Components/StickyFooter";

const styles = theme => ({
    root: {
        padding: theme.spacing(0)
    },
    overlay: {
        background: "linear-gradient(to right bottom, rgba(0, 14, 33, 0.3) 50%, rgba(154, 186, 54, 0.3))",
        top:"0",
        left:"0",
        width:"100%",
        "min-height":"100vh",
        // "z-index": "-1",
        // position:"absolute"
    }
  });


class App extends Component {
  componentDidMount() {
    var newAccount = null;
    try {
        window.ethereum.autoRefreshOnNetworkChange = false;
        newAccount = window.ethereum.on('accountsChanged', function (accounts) {
            return accounts[0]
        });
    } catch (err) {
        console.log(err);
    }
    this.reinit(newAccount);
  }

  reinit(newAccount) {

    if (newAccount === null) {

        // MetaMask is locked or the user has not connected any accounts
        console.log('Please install and connect to MetaMask.')
        this.setState({
            noWeb3: true
        });
    }
  }

  handleAccountsChanged = (accounts) => {
    try {
        if (accounts[0].toLowerCase() !== this.state.account.toLowerCase()) {
            console.log('Account has changed from', this.state.account, 'to', accounts[0]);
            this.setState({
                account: accounts[0].toString(),
                loggedIn: false,
                didClick: false,
            });
        }
    } catch (error) {
        console.error(error);
    }
  };

  handleChainChanged = async (chainId) => {
    try {
        if (chainId !== this.state.detectedNetwork) {
            
            console.log('Network has changed from', this.state.detectedNetwork, 'to', chainId);
            this.setState({
                detectedNetwork: chainId,
                loggedIn: false,
                didClick: false,
            });
        }
    } catch (error) {
        console.error(error);
    }
  };

getContractData = async () => {
    try {
        this.setState({isLoading: true})
        const {provider, data} = this.state
        for (var collection of data) {
            if (collection.saleStatus !== "upcoming") {
                const mintContract = new ethers.Contract(collection.mintingContract, ABI.abi, provider)
                const presaleMintContract = new ethers.Contract(collection.mintingContract, presaleABI.abi, provider)
                const maxSupply = await mintContract.maxSupply()
                collection["maxSupply"] = parseInt(maxSupply)
                const reservedSupply = await mintContract.reservedSupply()
                collection["reservedSupply"] = parseInt(reservedSupply)
                const reserveMinted = await mintContract.reserveMinted()
                collection["reserveMinted"] = parseInt(reserveMinted)
                const minted = await mintContract.minted()
                collection["minted"] = parseInt(minted)
                if (collection.hasBuyLimit) {
                    const buyLimit = await mintContract.buyLimit()
                    collection["buyLimit"] = parseInt(buyLimit)
                }
                if (collection.timedStart) {
                    const saleStart = await mintContract.saleStartTime()
                    collection["saleStart"] = Number(saleStart.mul(1000))
                }
                if (collection.presale) {
                    const presaleBuyLimitPerQualifyingId = await presaleMintContract.presaleBuyLimitPerQualifyingId()
                    collection['presaleBuyLimitPerQualifyingId'] = parseInt(presaleBuyLimitPerQualifyingId)
                    const presaleStartTime = await presaleMintContract.presaleStartTime()
                    collection["presaleStartTime"] = Number(presaleStartTime.mul(1000))
                }
                if (collection.claims) {
                    const carvestContract = new ethers.Contract(collection.mintingContract, carvestABI.abi, provider)
                    const claimLimitPerQualifyingId = await carvestContract.claimLimitPerQualifyingId()
                    collection['claimLimitPerQualifyingId'] = parseInt(claimLimitPerQualifyingId)
                    const claimStartTime = await carvestContract.claimStartTime()
                    collection["claimStartTime"] = Number(claimStartTime.mul(1000))
                }
            }
        }
        this.setState({data})
        this.setState({isLoading: false})

    } catch (err) {
        console.log(err)
    }
}

getWhitelistData = async () => {
    try {
        this.setState({isLoading: true})
        const {provider, data} = this.state
        for (var collection of data) {
            if (collection.whitelist) {
                const mintContract = new ethers.Contract(collection.mintingContract, ABI.abi, provider)
                const accountReservationAmount = await mintContract.whiteList(this.state.account)
                collection["accountReservationAmount"] = accountReservationAmount
            }
        }
        this.setState({data})
        this.setState({isLoading: false})

    } catch (err) {
        console.log(err)
    }
}

getBalances = async () => {
    try {
        this.setState({isLoading: true})
        const {provider} = this.state
        const nftContract = new ethers.Contract(data[0].nftContract, baseABI.abi, provider)
        const balanceMZKZ = await nftContract.balanceOf(this.state.account)
        this.setState({balanceMZKZ})
        this.setState({isLoading: false})

    } catch (err) {
        console.log(err)
    }
}

getTokenIds = async (bal) => {
    try {
        this.setState({isLoading: true})
        const {provider} = this.state
        const nftContract = new ethers.Contract(data[0].nftContract, baseABI.abi, provider)
        let accountIds = []
        let accountGenesisIds = []
        for (let i = 0; i < bal; i++) {
            const tokenId = await nftContract.tokenOfOwnerByIndex(this.state.account, i)
            accountIds.push(parseInt(tokenId))
            if (tokenId > 0 && tokenId < 201) {
                accountGenesisIds.push(parseInt(tokenId))
            }
        }
        this.setState({accountIds, accountGenesisIds})
        this.setState({isLoading: false})

    } catch (err) {
        console.log(err)
    }
}

getPresaleClaims = async () => {
    try {
        this.setState({isLoading: true})
        const {provider, data, accountGenesisIds} = this.state
        let unclaimedAccountGenesisIds = []
        for (var collection of data) {
            if (collection.presale) {
                const presaleMintContract = new ethers.Contract(collection.mintingContract, presaleABI.abi, provider)
                for (var id of accountGenesisIds) {
                    const claimedAmountPerQualifyingToken = await presaleMintContract.claimedTokens(id)
                    if (claimedAmountPerQualifyingToken < 1) {
                        unclaimedAccountGenesisIds.push(id)
                    }
                }
            }
        }
        this.setState({unclaimedAccountGenesisIds})
        this.setState({isLoading: false})

    } catch (err) {
        console.log(err)
    }
}

getClaims = async () => {
    try {
        this.setState({isLoading: true})
        const {provider, data, accountGenesisIds} = this.state
        let unclaimedAccountGenesisIdsForCarvest = []
        for (var collection of data) {
            if (collection.claims && collection.saleStatus !== "upcoming") {
                const claimMintContract = new ethers.Contract(collection.mintingContract, carvestABI.abi, provider)
                for (var id of accountGenesisIds) {
                    const claimedAmountPerQualifyingToken = await claimMintContract.claimedTokens(id)
                    if (claimedAmountPerQualifyingToken < 1) {
                        unclaimedAccountGenesisIdsForCarvest.push(id)
                    }
                }
            }
        }
        console.log(`Carvest Claims ${unclaimedAccountGenesisIdsForCarvest}`)
        this.setState({unclaimedAccountGenesisIdsForCarvest})
        this.setState({isLoading: false})

    } catch (err) {
        console.log(err)
    }
}

mintWhitelist =  async (contractAddress, price, amount) => {
    const {signer} = this.state
    const mintContract = new ethers.Contract(contractAddress, ABI.abi, signer)
    const priceBN = ethers.utils.parseEther(price)
    const value = priceBN.mul(amount)
    const overrides = {
        value: value
    }
    try {
        const txn = await mintContract.mintWhitelist(amount, overrides)
        console.log(txn)
        this.props.enqueueSnackbar("Transaction submitted to blockchain", { 
            variant: 'info',
        });

        const receipt = await txn.wait()
        let url = ""
        this.state.deployedNetwork !== 1

            ? url = `https://${this.state.deployedNetworkName}.etherscan.io/tx/${receipt.transactionHash}`
            : url = `https://etherscan.io/tx/${receipt.transactionHash}`

        const actionDismiss = (key) => (
          <React.Fragment className={this.props.classes.root}>
          <Button href={url} rel="noopener" target="_blank" color="primary" >
            {'Etherscan'}
          </Button>
          <IconButton size="small" onClick={() => { this.props.closeSnackbar(key) }}>
            <CloseIcon />
          </IconButton>
          </React.Fragment>
        );
        this.props.enqueueSnackbar("Transaction confirmed", { 
          variant: 'success',
          action: actionDismiss
        });

    } catch (err) {
        this.props.enqueueSnackbar("Error Minting: User denied transaction request.", {
            variant: 'error',
        });
    }
  }

mintPresale =  async (contractAddress, price, amount, id) => {
    const {signer} = this.state
    const mintContract = new ethers.Contract(contractAddress, presaleABI.abi, signer)
    const priceBN = ethers.utils.parseEther(price)
    const value = priceBN.mul(amount)
    const overrides = {
        value: value
    }
    try {
        const txn = await mintContract.mintPresale(amount, id, overrides)
        console.log(txn)
        this.props.enqueueSnackbar("Transaction submitted to blockchain", { 
            variant: 'info',
        });

        const receipt = await txn.wait()
        let url = ""
        this.state.deployedNetwork !== 1

            ? url = `https://${this.state.deployedNetworkName}.etherscan.io/tx/${receipt.transactionHash}`
            : url = `https://etherscan.io/tx/${receipt.transactionHash}`

        const actionDismiss = (key) => (
            <React.Fragment className={this.props.classes.root}>
            <Button href={url} rel="noopener" target="_blank" color="primary" >
            {'Etherscan'}
            </Button>
            <IconButton size="small" onClick={() => { this.props.closeSnackbar(key) }}>
            <CloseIcon />
            </IconButton>
            </React.Fragment>
        );
        this.props.enqueueSnackbar("Transaction confirmed", { 
            variant: 'success',
            action: actionDismiss
        });
        await this.getBalances()
        if (this.state.balanceMZKZ > 0) {
            await this.getTokenIds(this.state.balanceMZKZ)
        }
        if (this.state.accountGenesisIds.length > 0) {
            await this.getPresaleClaims()
        }

    } catch (err) {
        this.props.enqueueSnackbar("Error Minting: User denied transaction request.", {
            variant: 'error',
        });
    }
}
checkClaim =  async (contractAddress, id) => {
    const {provider} = this.state
    const mintContract = new ethers.Contract(contractAddress, carvestABI.abi, provider)
    
    try {
        this.setState({isLoadingCheckClaim: true})
        const specificTokenClaims = await mintContract.claimedTokens(id)
        this.setState({specificTokenClaims})
        this.setState({isLoadingCheckClaim: false})

    } catch (err) {
        console.log(err)
    }
}

mintClaim =  async (contractAddress, id) => {
    const {signer} = this.state
    const mintContract = new ethers.Contract(contractAddress, carvestABI.abi, signer)
    try {
        const txn = await mintContract.mintClaim(id)
        console.log(txn)
        this.props.enqueueSnackbar("Transaction submitted to blockchain", { 
            variant: 'info',
        });

        const receipt = await txn.wait()
        let url = ""
        this.state.deployedNetwork !== 1

            ? url = `https://${this.state.deployedNetworkName}.etherscan.io/tx/${receipt.transactionHash}`
            : url = `https://etherscan.io/tx/${receipt.transactionHash}`

        const actionDismiss = (key) => (
            <React.Fragment className={this.props.classes.root}>
            <Button href={url} rel="noopener" target="_blank" color="primary" >
            {'Etherscan'}
            </Button>
            <IconButton size="small" onClick={() => { this.props.closeSnackbar(key) }}>
            <CloseIcon />
            </IconButton>
            </React.Fragment>
        );
        this.props.enqueueSnackbar("Transaction confirmed", { 
            variant: 'success',
            action: actionDismiss
        });
        await this.getBalances()
        if (this.state.balanceMZKZ > 0) {
            await this.getTokenIds(this.state.balanceMZKZ)
        }
        if (this.state.accountGenesisIds.length > 0) {
            await this.getClaims()
        }

    } catch (err) {
        this.props.enqueueSnackbar("Error Minting: User denied transaction request.", {
            variant: 'error',
        });
    }
}

mintClaims =  async (contractAddress, ids) => {
    const {signer} = this.state
    const mintContract = new ethers.Contract(contractAddress, carvestABI.abi, signer)
    try {
        const txn = await mintContract.mintClaims(ids)
        console.log(txn)
        this.props.enqueueSnackbar("Transaction submitted to blockchain", { 
            variant: 'info',
        });

        const receipt = await txn.wait()
        let url = ""
        this.state.deployedNetwork !== 1

            ? url = `https://${this.state.deployedNetworkName}.etherscan.io/tx/${receipt.transactionHash}`
            : url = `https://etherscan.io/tx/${receipt.transactionHash}`

        const actionDismiss = (key) => (
            <React.Fragment className={this.props.classes.root}>
            <Button href={url} rel="noopener" target="_blank" color="primary" >
            {'Etherscan'}
            </Button>
            <IconButton size="small" onClick={() => { this.props.closeSnackbar(key) }}>
            <CloseIcon />
            </IconButton>
            </React.Fragment>
        );
        this.props.enqueueSnackbar("Transaction confirmed", { 
            variant: 'success',
            action: actionDismiss
        });
        await this.getBalances()
        if (this.state.balanceMZKZ > 0) {
            await this.getTokenIds(this.state.balanceMZKZ)
        }
        if (this.state.accountGenesisIds.length > 0) {
            await this.getClaims()
        }

    } catch (err) {
        this.props.enqueueSnackbar("Error Minting: User denied transaction request.", {
            variant: 'error',
        });
    }
}

 mint =  async (contractAddress, price, amount) => {
    const {signer} = this.state
    const mintContract = new ethers.Contract(contractAddress, ABI.abi, signer)
    const priceBN = ethers.utils.parseEther(price)
    const value = priceBN.mul(amount)
    const overrides = {
        value: value
    }
    try {
        const txn = await mintContract.mint(amount, overrides)
        console.log(txn)
        this.props.enqueueSnackbar("Transaction submitted to blockchain", { 
            variant: 'info',
        });

        const receipt = await txn.wait()
        let url = ""
        this.state.deployedNetwork !== 1

            ? url = `https://${this.state.deployedNetworkName}.etherscan.io/tx/${receipt.transactionHash}`
            : url = `https://etherscan.io/tx/${receipt.transactionHash}`

        const actionDismiss = (key) => (
          <React.Fragment className={this.props.classes.root}>
          <Button href={url} rel="noopener" target="_blank" color="primary" >
            {'Etherscan'}
          </Button>
          <IconButton size="small" onClick={() => { this.props.closeSnackbar(key) }}>
            <CloseIcon />
          </IconButton>
          </React.Fragment>
        );
        this.props.enqueueSnackbar("Transaction confirmed", { 
          variant: 'success',
          action: actionDismiss
        });


    } catch (err) {
        this.props.enqueueSnackbar("Error Minting: User denied transaction request.", {
            variant: 'error',
        });
    }
  }

  async onClick() {

    try {
        await window.ethereum.request({method: 'eth_requestAccounts'})
        // console.log(accounts[0])
        // const web3 = await new Web3(Web3.givenProvider) // || "http://localhost:8545")
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const detectedNetwork = await provider.getNetwork()
        
        const account = await signer.getAddress()
        this.setState({
            // web3,
            provider,
            signer,
            account: account.toString()
        })
        // web3.currentProvider.publicConfigStore.on('update', this.checkForUpdate)
        window.ethereum.on('accountsChanged', this.handleAccountsChanged);
        window.ethereum.on('chainChanged', this.handleChainChanged);
        // var ret = await detectNetwork(web3.currentProvider);
        // const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        this.setState({
            detectedNetwork: detectedNetwork.chainId,
            detectedNetworkName: detectedNetwork.name
        });
        console.log(this.state.detectedNetwork, this.state.detectedNetworkName, this.state.deployedNetwork, this.state.deployedNetworkName);
        if (Number(this.state.detectedNetwork) !== Number(this.state.deployedNetwork)) {
            const snackText = ("Wrong Network: Please switch to " + this.state.deployedNetworkName);
            this.props.enqueueSnackbar(snackText, {
                variant: 'error',
            });
        } else {
            var text = "I love cats!";
            // var msg = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(text));
            try {
                const signature = await signer.signMessage(text)
                this.setState({signedMessage: signature});
                console.log(signature)
    
                this.setState({
                    loggedIn: !this.state.loggedIn,
                    didClick: true
                });
            } catch (err) {
                this.props.enqueueSnackbar("Error Connecting: User denied signature request.", {
                    variant: 'error',
                });
                console.log(err)
            }

            try {
                await this.getContractData()
                await this.getWhitelistData()
                await this.getBalances()
                if (this.state.balanceMZKZ > 0) {
                    await this.getTokenIds(this.state.balanceMZKZ)
                }
                if (this.state.accountGenesisIds.length > 0) {
                    await this.getPresaleClaims()
                    await this.getClaims()
                }
            } catch (err) {
                console.log(err)
            }
        }

    } catch (error) {
        // Catch any errors for any of the above operations.
        if (this.state.noWeb3) {
            this.props.enqueueSnackbar("No Web3 detected: Please install Metamask.", {
                variant: 'error',
            });
        } else {
            this.props.enqueueSnackbar("Error Connecting: User denied connection request.", {
                variant: 'error',
            });
            console.error(error);
        }
    }
    this.setState({
        didClick: true
    });
  };

  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.reinit = this.reinit.bind(this)
    this.mint = this.mint.bind(this);
    this.mintWhitelist = this.mintWhitelist.bind(this)
    this.mintPresale = this.mintPresale.bind(this)
    this.checkClaim = this.checkClaim.bind(this)
    this.mintClaim = this.mintClaim.bind(this)
    this.mintClaims = this.mintClaims.bind(this)
    this.getContractData = this.getContractData.bind(this)
    this.getWhitelistData = this.getWhitelistData.bind(this)
    this.getBalances = this.getBalances.bind(this)
    this.getTokenIds = this.getTokenIds.bind(this)

    this.state = {
        loggedIn: false,
        didClick: false,
        web3: null,
        noWeb3: null,
        account: null,
        isLoading: false,
        isLoadingCheckClaim: false,
        balanceMZKZ: 0,
        accountIds: [],
        accountGenesisIds: [],
        unclaimedAccountGenesisIds: [],
        unclaimedAccountGenesisIdsForCarvest: [],
        specificTokenClaims: "??",
        detectedNetwork: null,
        detectedNetworkName: null,
        deployedNetwork: 1,
        deployedNetworkName: "Homestead",
        appVersion: "v0.0.1",
        featuredMint: "/carvest-time",
        data: data
    }
  };

  render() {
    const {classes} = this.props;
    return (
        <div className={classes.root}>
            <div className={classes.overlay}>
            <BrowserRouter>
                <Navigation 
                    onClick={this.onClick} 
                    deployedNetwork={this.state.deployedNetwork}
                    detectedNetwork={this.state.detectedNetwork}
                    deployedNetworkName={this.state.deployedNetworkName}
                    detectedNetworkName={this.state.detectedNetworkName}
                    account={this.state.account} 
                    loggedIn={this.state.loggedIn} 
                    appVersion={this.state.appVersion} 
                    featuredMint={this.state.featuredMint}/>
                <Switch>
                    <Route path="/" render={(props) => <Home {...props} isAuthed={true} />} exact/>
                    <Route path="/artworks" render={(props) => <Artworks {...props} data={data}/>} exact/>
                    <Route path="/about" component={About} exact/>
                    {data.map((metaData, index) => {
                        return (
                            <Route 
                                path={`/${metaData.slug}`} 
                                render={(props) => (
                                    <AssetPage {...props} 
                                        isLoading={this.state.isLoading} 
                                        isLoadingCheckClaim={this.state.isLoadingCheckClaim}
                                        loggedIn={this.state.loggedIn} 
                                        balanceMZKZ={this.state.balanceMZKZ}
                                        accountIds={this.state.accountIds}
                                        accountGenesisIds={this.state.accountGenesisIds}
                                        unclaimedAccountGenesisIds={this.state.unclaimedAccountGenesisIds}
                                        unclaimedAccountGenesisIdsForCarvest={this.state.unclaimedAccountGenesisIdsForCarvest}
                                        specificTokenClaims={this.state.specificTokenClaims}
                                        metaData={metaData} 
                                        mint={this.mint} 
                                        mintWhitelist={this.mintWhitelist}
                                        mintPresale={this.mintPresale}
                                        checkClaim={this.checkClaim}
                                        mintClaim={this.mintClaim}
                                        mintClaims={this.mintClaims}
                                        getBalances={this.getBalances}
                                        getTokenIds={this.getTokenIds}
                                        getContractData={this.getContractData}
                                        getWhitelistData={this.getWhitelistData}
                                        deployedNetwork={this.state.deployedNetwork}
                                        deployedNetworkName={this.state.deployedNetworkName}
                                    />
                                )} 
                                exact key={index}
                            />
                        )
                    })}
                    <Route component={Error}/>
                </Switch>
                <StickyFooter/>
            </BrowserRouter>
            </div> 
        </div>
    )
  }
}

export default withStyles(styles)(withSnackbar(withTheme(App)));
