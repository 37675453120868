import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button'
import { withStyles, withTheme } from '@material-ui/core/styles';
import Nexters from "../Media/Images/nexters.svg";

const styles = theme => ({
    root: {
    //   ...theme.mixins.gutters(),
    //   display: "flex",
      paddingTop: theme.spacing(4),
      textAlign: "center",
    },
    continueStyle: {
        textAlign: "center",
        animation: "$drift, $fade-in",
        "animation-duration": "5s, 200ms",
        "animation-iteration-count": "infinite, 1",
        "animation-timing-function": "linear",
        // display: "block",
        height: "56px",
        "border-radius": "5px",
        border:" 2px solid #e5e5e5",
        "background-image": `url(${Nexters})`,
        "background-color": "#9ABA36",
        width: "65%",
        "background-size": "111px 73px",
        "font-size": "1.5em",
        "font-family": "Permanent Marker",
        "margin-top": "16px",
        "box-shadow": "4px 4px 8px rgba(255, 255, 255, 0.30)",
        transition: "transform 200ms",
        appearance: "none",
        "&:hover": {
            transform: "scale(1.05)",
        },
        "&:active": {
            transform: "scale(0.98)",
        },
        "&:focus": {
            color: "#9ABA36",
            "border-color": "#9ABA36"
        }
    },
    navlink: {
        'text-decoration': 'none',
    },
    "@keyframes drift": {
        "100%": {
        "background-position": "111px 73px"
        }
    },
    "@keyframes fade-in": {
        from: {
            opacity: "0"
        },
        to: {
            opacity: "1"
        }
    }

  });

const ContinueButton = (props) => {

    const {
        classes, 
        onClick,
        callToAction
    } = props

    return(
        <div className={classes.root}>
        {callToAction ? 
        (
            <NavLink className={classes.navlink} to={`/${callToAction}`}>
                <Button className={classes.continueStyle} variant="contained">
                    {callToAction}
                </Button>
            </NavLink>

        ) :
        (
            <Button className={classes.continueStyle} variant="contained" onClick={onClick}>
                {"Continue"}
            </Button>
        )
        }
        </div>
    )
  }

  export default withStyles(styles)(withTheme(ContinueButton))