import React from 'react';
import date from 'date-and-time';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'; 
import Buttonbase from '@material-ui/core/ButtonBase'; 
import theAmbush from '../Media/Images/the-ambush-featured-image.png';

const styles = theme => ({
   root: {
      // display: "flex",
      padding: theme.spacing(3),
   },
   navlink: {
      // background:theme.palette.primary.light, 
      // color: theme.palette.common.white,
      'text-decoration': 'none',
   },
   stagePaper: {
      width: 1200,
      height: 400,
      "background-position": "25% 75%",
      backgroundColor: theme.palette.primary.main,
      background: `url(${theAmbush})`,
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      perspective: "1000px",
      "perspective-origin": "50% 50%",
      animation: "$animation 20s linear infinite",
      "animation-play-state": "paused",
   },
   stageText: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
   },
   stageButtonImage: {
      color: theme.palette.common.white,
      position: 'relative',
      '&:hover, &$focusVisible': {
         zIndex: 1,
         '& $imageBackdrop': {
            opacity: 0.15,
         },
         '& $imageMarked': {
            opacity: 0,
         },
         '& $imageTitle': {
            border: '4px solid currentColor',
         },
         '& $stagePaper': {
            "animation-play-state": "running",
         }
      },
   },
   focusVisible: {},
   imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.50,
      transition: theme.transitions.create('opacity'),
   },
   imageButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
   },
   imageMarked: {
      height: 3,
      width: 18,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      left: 'calc(50% - 9px)',
      transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
      position: 'relative',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
      border: '4px solid transparent',
      "border-radius": "10px",
      "font-family": "Permanent Marker"
    },
   "@keyframes animation": {
      "0%": {
         "background-position": "0% 50%",
      },
      "100%": {
         "background-position": "0% 150%",
      }
   }
});
 
class ArtworksStub extends React.Component {

   componentDidMount() {
      window.scrollTo(0, 0)
    }


    render () {
      const {classes, metaData} = this.props;

      var saleStart = new Date(metaData.saleStart)

       return (
          <div className={classes.root}>
            <Buttonbase
                focusRipple
                className={classes.stageButtonImage}
                focusVisibleClassName={classes.focusVisible}
            >
                <NavLink className={classes.navlink} to={metaData.slug}>
                <Paper className={classes.stagePaper} style={{background: `url(${require('../Media/Images/' + metaData.featuredImage + '.png').default})`}}>
                <span className={classes.imageBackdrop} />
                    <span className={classes.imageButton}>
                        <Grid container direction='column' justify="space-between" alignItems="center" spacing={2}>
                            <Grid item>
                        <Typography className={classes.imageTitle} variant='h4' justify='center'>
                            {metaData.name}
                            <span className={classes.imageMarked} />
                        </Typography>
                        </Grid>
                        <Grid item xs>
                        <Typography  variant='body1' justify='center'>
                            {Date.now() < saleStart
                                ? `Dropping ${date.format(saleStart, "MM/DD/YYYY h:mm A")} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`
                                : metaData.saleStatus === "active"
                                ? `Mint now!`
                                : `View Series`
                            }
                        </Typography>
                        </Grid>
                        </Grid>
                    </span>
                </Paper>
                </NavLink>
            </Buttonbase>
          </div>
       )
    }
}
 
export default withStyles(styles)(withSnackbar(withTheme(ArtworksStub)));