import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "@fontsource/permanent-marker";
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';

const theme = createTheme({
  palette: {
    // type: 'dark',
    background: {
      default: '#9DA9A0', // morning blue
      // paper: '#fbf6eb', // light yellow
      // paper: '#EFECCA',
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#395B50', // Feldgrau
      // main: '#503D3F', //dark liver
      // main: '#f9aa33', //mustard
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: '#CEC075', // Dark Khaki
      // main: '#374d59', //slate
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    text: {
      primary: '#ff6b60' //princeton orange
    }
    // error: will use the default color
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <App theme={theme}/>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
