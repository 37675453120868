import React from "react";
import { WindupChildren, CharWrapper, Linebreaker, Pause  } from "windups";
// import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
    //   ...theme.mixins.gutters(),
      display: "flex",
      paddingTop: theme.spacing(),
    //   paddingBottom: theme.spacing.unit * 4,
    },
    rightBubble: {
        padding: "12px",
        color: "white",
        "border-radius": "3px",
        "background-color": "#4281A4",
        transform: "skew(5deg, 3deg)",
        "white-space": "pre",
        "font-size": "24px",
        "align-self": "flex-end",
        "box-shadow": "-2px 2px 7px rgba(0, 0, 0, 0.05)"
    },
    leftBubble: {
        padding: "12px",
        color: "black",
        "border-radius": "3px",
        "background-color": "#E6B89C",
        transform: "skew(-5deg, -3deg)",
        "white-space": "pre-wrap",
        "font-size": "24px",
        "align-self": "flex-start",
        "box-shadow": "2px 2px 7px rgba(0, 0, 0, 0.05)"
    },
    chatChar: {
        animation: "$enter 150ms",
        // "animation-play-state": "running",
        // display: "inline-block"
    },
    "@keyframes enter": {
        from: {
          opacity: "0",
          transform: "scale(0.1) rotate(-180deg) translateY(-100%)"
        },
        to: {
          opacity: "1",
          transform: "scale(1) rotate(0deg) translateY(0)"
        }
      },

  });



export const SpeechBubble = (props) => {
    const { 
        classes,
        pauseDuration
    } = props;

    const animChar = ({children}) => {
        return <span className={classes.chatChar}>{children}</span>
    }

    return (
        <div className={classes.root}>
        <Linebreaker width={300} fontStyle={'12px "Permant Marker", cursive'}>
            <WindupChildren onFinished={props.onFinished}>
                <CharWrapper element={animChar}>
                    <div className={props.speakerDirection === "Left" ? classes.leftBubble : classes.rightBubble}>
                        {props.text}
                        <Pause ms={pauseDuration}/>
                    </div>
                    {/* {props.text.map((text, index) => {
                        return (
                            <div className={props.speaker === "Left" ? classes.leftBubble : classes.rightBubble} key={index}>
                                {text}
                                <Pause ms={500}/>
                            </div>
                        )
                        })} */}
                </CharWrapper>
            </WindupChildren>
        </Linebreaker>
        </div>
    );
};

const Chat = (props) => {
    const [linesToShow, setLinesToShow] = React.useState(0);

    const { 
        classes, 
        text,
        speakerDirection,
        onFinished
    } = props;
  
    const setLines = (num) => {
      setTimeout(() => {
        setLinesToShow(num);
      }, 300);
    };
  
    return (
        <div >
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems={speakerDirection === "Left" ? "flex-start" : "flex-end"}
            >
            {text.map((textLine, index) => {
                if (index <= linesToShow) {
                    return(
                        <SpeechBubble
                            speakerDirection={speakerDirection}
                            text={textLine}
                            onFinished={index === text.length - 1 ? onFinished : () => setLines(index+1)}
                            classes={classes}
                            pauseDuration={index === text.length - 1 ? 1000 : 500}
                            key={index}
                        />
                    )
                } else {
                    return <React.Fragment key={index}/>
                }
            })}
                {/* <SpeechBubble
                    speakerDirection={speakerDirection}
                    text={text[0]}
                    onFinished={() => setLines(1)}
                    classes={classes}
                    pauseDuration={500}
                />
            {linesToShow >= 2 && (
                <SpeechBubble
                    speakerDirection={speakerDirection}
                    text={text[1]}
                    onFinished={() => setLines(2)}
                    classes={classes}
                    pauseDuration={500}
                />
            )}
            {linesToShow >= 3 && (
                <SpeechBubble
                    speakerDirection={speakerDirection}
                    text={text[2]}
                    onFinished={() => setLines(3)}
                    classes={classes}
                    pauseDuration={500}
                />
            )}
            {linesToShow >= 4 && (
                <SpeechBubble
                    speakerDirection={speakerDirection}
                    text={text[3]}
                    onFinished={onFinished}
                    classes={classes}
                    pauseDuration={1500}
                />
            )} */}
            </Grid>
        </div>
    );
};

export default withStyles(styles)(Chat)