import React, {Component} from "react";
import {withSnackbar} from 'notistack';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import { Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Launch from '@material-ui/icons/Launch';
import Copy from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const styles = theme => ({
   root: {
    //   display: "flex",
    //   padding: theme.spacing(3),
      "max-width": "100%"
   },
   navlink: {
      // background:theme.palette.primary.light, 
      // color: theme.palette.common.white,
      'text-decoration': 'none',
   },
   paper: {
    padding: 20,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    background:theme.palette.primary.main,
    "max-width": "100%"
   },
   metaDataText: {
    "font-family": "Permanent Marker"
   }
});
 
class DescriptionPanel extends Component {

    copyToClipboard = () => {
        this.setState({reqCopy: true})
        this.props.enqueueSnackbar(("Address copied to clipboard" ), { 
          });
      };

   render() {
      const {
        classes,
        metaData,
        isLoading,
        loggedIn,
        deployedNetwork,
        deployedNetworkName
        } = this.props;

        let mintsRemaining = parseInt(metaData.maxSupply) - parseInt(metaData.minted) - parseInt(metaData.reservedSupply) + parseInt(metaData.reserveMinted)
        if (metaData.saleStatus === "upcoming") {
            mintsRemaining = metaData.availableMints
        }

      return (
         <div className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
            <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
            >
                <Grid item xs={12} zeroMinWidth>
                    <Typography variant="h5" className={classes.metaDataText}>
                        {metaData.name}
                    </Typography>
                </Grid>
                <Grid item xs={12} zeroMinWidth>
                    <Typography className={classes.metaDataText}>
                        {metaData.description}
                    </Typography>
                </Grid>
                {typeof metaData.collDescription !== 'undefined'
                   ? (
                        <Grid item xs={12} zeroMinWidth>
                            <Typography className={classes.metaDataText}>
                                {metaData.collDescription}
                            </Typography>
                        </Grid>
                    )
                    : null
                }
                <Grid item xs={12} zeroMinWidth>
                    <Typography className={classes.metaDataText}>
                        {`Supply: ${metaData.supply}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} zeroMinWidth>
                    <Typography className={classes.metaDataText}>
                        {`Creator(s): ${metaData.creator}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} zeroMinWidth>
                    <Typography className={classes.metaDataText}>
                        {`Year: ${metaData.year}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} zeroMinWidth>
                    <Typography className={classes.metaDataText}>
                        {`Sale Price: ${metaData.salePrice} ETH`}
                    </Typography>
                </Grid>
                <Grid item xs={12} zeroMinWidth>
                    <Typography className={classes.metaDataText}>
                        {`Available: `}
                        {!loggedIn && metaData.saleStatus === "inactive" ? metaData.availableMints : !loggedIn ? "??" : isLoading ? <CircularProgress size={20} thickness={5} disableShrink={true} color="secondary" />  : mintsRemaining}
                        {`/${metaData.supply}`}
                    </Typography>
                </Grid>
                {metaData.saleStatus !== "upcoming"
                    ? (
                        <React.Fragment>
                            <Grid item xs={12} zeroMinWidth>
                                <Typography className={classes.metaDataText}>
                                    {`Minting Contract: ${metaData.mintingContract}`}
                                    <Tooltip title="Copy Address">
                                        <CopyToClipboard text={metaData.mintingContract} onCopy={() => this.setState({copied: true})}>
                                            <IconButton color="secondary" size="small" onClick={this.copyToClipboard}>
                                                <Copy/>
                                            </IconButton>
                                        </CopyToClipboard>
                                    </Tooltip>
                                    <Tooltip title="Etherscan">
                                        <IconButton 
                                            color="secondary" 
                                            size="small" 
                                            href={deployedNetwork !== 1
                                                ? `https://${deployedNetworkName}.etherscan.io/address/${metaData.mintingContract}`
                                                : `https://etherscan.io/address/${metaData.mintingContract}`
                                            } 
                                            rel="noopener" 
                                            target="_blank" 
                                        >
                                            <Launch/>
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} zeroMinWidth>
                                <Typography className={classes.metaDataText}>
                                    {`NFT Contract: ${metaData.nftContract}`}
                                    <Tooltip title="Copy Address">
                                        <CopyToClipboard text={metaData.nftContract} onCopy={() => this.setState({copied: true})}>
                                            <IconButton color="secondary" size="small" onClick={this.copyToClipboard}>
                                                <Copy/>
                                            </IconButton>
                                        </CopyToClipboard>
                                    </Tooltip>
                                    <Tooltip title="Etherscan">
                                        <IconButton 
                                            color="secondary" 
                                            size="small" 
                                            href={deployedNetwork !== 1
                                                ? `https://${deployedNetworkName}.etherscan.io/address/${metaData.nftContract}`
                                                : `https://etherscan.io/address/${metaData.nftContract}`
                                            } 
                                            rel="noopener" 
                                            target="_blank" 
                                        >
                                            <Launch/>
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    ) : null
                }
            </Grid>
            </Paper>
         </div>
      );
   }
}
 
export default withStyles(styles)(withSnackbar(withTheme(DescriptionPanel)));