import React from "react";
import miezeProfile from "../Media/Images/miezeProfile.png"
import katzeProfile from "../Media/Images/katzeProfile.png"
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Speaker from './Speaker';
import ContinueButton from '../Components/ContinueButton';

const styles = () => ({})

const DialogueGrid = (props) => {
    const [speakerToShow, setSpeakerToShow] = React.useState(0);
    const [chatFinished, setIsChatFinished] = React.useState(false);

    const {
      callToAction,
      dialogue,
  } = props;

    const setSpeaker = (num) => {
      setTimeout(() => {
        setSpeakerToShow(num);
      }, 300);
    };

    const onClickContinue = () => {
      setIsChatFinished(false)
      setSpeaker(speakerToShow+1)
    }

      return (
        <div >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="stretch"
            spacing={2}
          >
          <Grid item xs={4}/>
          <Grid item xs={4}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={2}
          >
          {dialogue.map((text, index) => {
            if (index <= speakerToShow) {
                return(
                  <Speaker 
                    text={text} 
                    speakerDirection={index % 2 === 0 ? "Left" : "Right"} 
                    speakerImage={index % 2 === 0 ? miezeProfile : katzeProfile}
                    speakerName={index % 2 === 0 ? "Mieze" : "Katze"} 
                    onFinished={index % 2 === 1 ? () => setIsChatFinished(true) : () => setSpeaker(index+1)}
                    key={index}
                  /> 
                )
            } else {
                return <React.Fragment key={index}/>
            }
          })}
          {chatFinished && speakerToShow < dialogue.length ? <ContinueButton onClick={() => onClickContinue()} callToAction={speakerToShow === dialogue.length - 1 ? callToAction : null}/> : null}
          </Grid>
          </Grid>
          <Grid item xs={4}/>
          </Grid>
        </div>
       );
}
 
export default withStyles(styles)(DialogueGrid);