import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import ArtworksStub from '../Components/ArtworksStub'
import Speaker from '../Components/Speaker';
import artworksDialogue from '../Dialogues/ArtworksDialogue'
import katzeProfile from "../Media/Images/katzeProfile.png"

const styles = theme => ({
   root: {
      padding: theme.spacing(3),
   },
   artStub: {
    "max-width": "100%"
 }
});
 
class ArtWorks extends React.Component {

    componentDidMount() {
      window.scrollTo(0, 0)
    }

    render () {
        const {classes, data} = this.props;
        let anyActive = false;
        let anyUpcoming = false;

        for (const metaData of data) {
            if (Date.now() > new Date(metaData.saleStart) && metaData.saleStatus === "active") {
                anyActive = true
            };
            if (Date.now() < new Date(metaData.saleStart)) {
                anyUpcoming = true
            };
        }

       return (
          <div className={classes.root}>
            <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={5}
            >
                <Grid item xs={4}/>
                <Grid item xs={4}/>
                <Grid item xs={4}>
                    <Speaker 
                        text={artworksDialogue} 
                        speakerDirection={"Right"} 
                        speakerImage={katzeProfile}
                        speakerName={"Katze"} 
                    />
                </Grid>
            </Grid>

            <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            spacing={5}
            >
                {anyActive
                    ? <h1>Active Mints</h1>
                    : null
                }
                
                {data.map((metaData, index) => {
                    if (Date.now() > new Date(metaData.saleStart) && metaData.saleStatus === "active") {
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={12} key={index}>
                                    <ArtworksStub className={classes.artStub} metaData={metaData} key={index}/>
                                </Grid>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment/>
                        )
                    }
                })}

                
                {anyUpcoming
                    ? <h1>Upcoming Mints</h1>
                    : null
                }

                {data.map((metaData, index) => {
                    if (Date.now() < new Date(metaData.saleStart)) {
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={12} key={index}>
                                    <ArtworksStub className={classes.artStub} metaData={metaData} key={index}/>
                                </Grid>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment/>
                        )
                    }
                })}

                <h1>Completed Series</h1>

                {data.map((metaData, index) => {
                    if (metaData.saleStatus === "inactive") {
                        return (
                            <React.Fragment key={index}>
                                <Grid item xs={12} key={index}>
                                    <ArtworksStub className={classes.artStub} metaData={metaData} key={index}/>
                                </Grid>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment/>
                        )
                    }
                })}

            </Grid>
          </div>
       )
    }
}
 
export default withStyles(styles)(withSnackbar(withTheme(ArtWorks)));