import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Pets, Twitter} from '@material-ui/icons';
import OpenSeaIcon from '../Media/Images/OpenSeaIcon.svg'
import Discord from '../Media/Images/discord.svg'


const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    "min-height": '100vh',
    position: "relative",
    "padding-bottom": "60px",
  },
  footerStyle: {
    "text-align": "center",
    height: "60px",
    color: "#CEC075",
    bottom: "0",
    left: "0",
    right: "0",
    position: "absolute",
    "font-size": "24px"
    },
    icons: {
        color: "#CEC075", 
    }
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <footer className={classes.footerStyle}>
        {"Made with "}
        <Pets className={classes.icons} />
        {" by miezekatze"}
        {` © ${new Date().getFullYear()} || `}
        <a href="https://twitter.com/MZKZxyz" target="_blank" rel="noreferrer">
            <Twitter className={classes.icons} />
        </a>
        {" || "}
        <a href="https://discord.gg/wMj8NknWee" target="_blank" rel="noreferrer">
        <img src={Discord} alt={"Discord"} />
        </a>
        {" || "}
        <a href="https://opensea.io/collection/mzkz" target="_blank" rel="noreferrer">
        <img src={OpenSeaIcon} alt={"OpenSea"} />
        </a>

      </footer>
    </div>
  );
}
