import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, withTheme} from '@material-ui/core/styles';
import Blockies from 'react-blockies';
import Dialog from '@material-ui/core/Dialog';
import Search from '@material-ui/icons/Search';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Slide from '@material-ui/core/Slide';
import metamask_img from "../Media/Images/metamask.png";
import ledger_img from "../Media/Images/LedgerIcon.png";
import enjin_logo from "../Media/Images/enjin-icon.png";

const styles = theme => ({
    root: {
        flexGrow: 1,
      },
    divider: {'background-color': theme.palette.secondary.main,
    },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        background:theme.palette.primary.main,
      },
      paperScrollPaper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.getContrastText(theme.palette.primary.main),
        background:theme.palette.primary.main,
      },
      paperScrollBody: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background:theme.palette.secondary.main,
      },
      dialogTitle: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        background:theme.palette.primary.main,
      },
      navText: {
        "font-family": "Permanent Marker"
        }
  });

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogConnectToWallet extends Component {
    state = {
        open: false,
        
        values: {
            isApproved: this.props.isApproved,
        }
    }

    handleToggle = () => {
      this.setState({open: !this.state.open, values: {isApproved: this.props.isApproved}})
    }

    handleCheck = (prop) => event => {
      this.setState({ values: {
        ...this.state.values, [prop]: event.target.checked 
      }})
    };

    onSubmit = () => {
      if (this.state.values.isApproved !== this.props.isApproved) {
        this.props.setApprovalForAll(this.props.contractAddress, !this.props.isApproved);
        this.props.enqueueSnackbar("Confirm request to complete transaction", { 
          variant: 'warning',
        });
        this.handleToggle();
      } else {
        this.props.enqueueSnackbar(("The property is already set to " + this.props.isApproved), { 
          variant: 'error',
        });
      }

    }

    render() {
        const { open } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.root}>

            { this.props.loggedIn && this.props.account !== null
                    ? (                    
                        <Button 
                            variant={this.props.variant}
                            color="secondary" 
                            size="small"
                            onClick={this.handleToggle}
                            endIcon={<Blockies scale={3} seed={this.props.account}/>}
                        >
                        {this.props.account.slice(0,6) + "..." + this.props.account.slice(37,41)}
                        </Button>
                    )
                    : (
                        <Button 
                        className={classes.navText}
                        variant={this.props.variant}
                        color="secondary" 
                        onClick={this.handleToggle}
                        size="small"
                        >
                        {"Connect to a Wallet"}
                        </Button>
                        )
                    }

        <Dialog 
        classes={{
            paperScrollPaper: classes.paperScrollPaper,
            paperScrollBody: classes.paperScrollBody,
        }}
        TransitionComponent={Transition} 
        open={open} 
        onClose={this.handleToggle} 
        aria-labelledby="dialog-connect-to-wallet"
        fullWidth={true}
        maxWidth='sm'
      >
            <DialogTitle 
                align="left" 
                id="dialog-connect-to-wallet" 
                classes={{root: classes.dialogTitle}}
                >
                    {"Connect to a Wallet"}
                </DialogTitle>
          <Divider classes={{
                root: classes.divider
            }}/>
            <br/><br/><br/>
        <DialogContent >
            <Grid 
                container
                direction="row"
                spacing={3}
                alignItems="stretch"
            >
                <Grid xs={6} item >
                    <Button fullWidth={true} variant='outlined' color='secondary' size="large" onClick={this.props.onClick}>
                        <Grid 
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                        <Grid item >
                        {"MetaMask"}
                        </Grid>

                        <Grid item >
                        <img src={metamask_img} alt="metamask-logo-img" height="40"/>
                        </Grid>


                        </Grid>

                    </Button>
                </Grid>
                <Grid xs={6} item >
                  <Tooltip title="Coming Soon!" placement="top" arrow={true}>
                    <span>
                      <Button disabled={true} fullWidth={true} variant='outlined' color='secondary' size="large" onClick={this.props.onClick}>
                        <Grid 
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                          <Grid item >
                          {"Enjin"}
                          </Grid>

                          <Grid item >
                            <img src={enjin_logo} alt="enjin-logo-img" height="40"/>
                          </Grid>
                        </Grid>
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid xs={6} item >
                  <Tooltip title="Coming Soon!" placement="top" arrow={true}>
                    <span>
                      <Button disabled={true} fullWidth={true} variant='outlined' color='secondary' size="large" onClick={this.props.onClick}>
                        <Grid 
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                          <Grid item >
                            {"Ledger"}
                          </Grid>

                          <Grid item >
                            <img src={ledger_img} alt="ledger-logo-img" height="40"/>
                          </Grid>
                        </Grid>
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid xs={6} item >
                <Tooltip title="Coming Soon!" placement="top" arrow={true}>
                  <span>
                    <Button disabled={true} fullWidth={true} variant='outlined' color='secondary' size="large" onClick={this.props.getCookie}>
                        <Grid 
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                          <Grid item >
                            {"Browse"}
                          </Grid>

                          <Grid item >
                            <Search style={{ fontSize: 40 }}/> 
                          </Grid>
                        </Grid>
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>

            </Grid>


        </DialogContent>
        <br/><br/><br/>
        <DialogActions>
          <Button onClick={this.handleToggle} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    
            </div>
          );
    }
}

export default (withStyles(styles))(withTheme((withSnackbar(DialogConnectToWallet))));