import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, withTheme} from '@material-ui/core/styles';
import Blockies from 'react-blockies';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Launch from '@material-ui/icons/Launch';
import Copy from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
// import RecentTransactionsPaper from "./RecentTransactionsPaper.js";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const styles = theme => ({
    root: {
        flexGrow: 1,
      },
    divider: {
        'background-color': theme.palette.secondary.main,
    },
      paperScrollPaper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.getContrastText(theme.palette.primary.main),
        background:theme.palette.primary.main,
    },
    dialogTextBox: {
        padding: theme.spacing(2),
    },
    dialogContextBox: {
        padding: theme.spacing(0),
    },
    box: {
        padding: theme.spacing(1),
        background:theme.palette.primary.main,
        borderStyle: "solid",
        borderColor: theme.palette.secondary.main,
        borderWidth: "1px",
    },
    shape: {
        backgroundColor: theme.palette.primary.main,
        width: 10,
        height: 40,
    },
    paddedGrid: {
        padding: theme.spacing(2),
    },
  });

  const StyledBadgeRed = withStyles(theme => ({
    badge: {
    //   backgroundColor: '#44b700',
      backgroundColor: 'red',
      color: 'red',
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }))(Badge);

const StyledBadgeGreen = withStyles(theme => ({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
    },
  }))(Badge);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogAddressInfo extends Component {
    constructor(props) {
        super(props)
        this.handleToggle = this.handleToggle.bind(this);
    }
    state = {
        
        open: false,
        copied: false,
        reqCopy: false,
        account: this.props.account,
        
        values: {
            isApproved: this.props.isApproved,
        },
        urlAccount: this.props.deployedNetwork !== 1
            ? `https://${this.props.deployedNetworkName}.etherscan.io/address/${this.props.account}`
            : `https://etherscan.io/address/${this.props.account}`
        }

    handleToggle = () => {
      this.setState({open: !this.state.open, values: {isApproved: this.props.isApproved}})
    }

    onSubmit = () => {
      if (this.state.values.isApproved !== this.props.isApproved) {
        this.props.setApprovalForAll(this.props.contractAddress, !this.props.isApproved);
        this.props.enqueueSnackbar("Confirm request to complete transaction", { 
          variant: 'warning',
        });
        this.handleToggle();
      } else {
        this.props.enqueueSnackbar(("The property is already set to " + this.props.isApproved), { 
          variant: 'error',
        });
      }

    }

    copyToClipboard = () => {
        console.log("I want to copy " + this.props.account)
        this.setState({reqCopy: true})
        this.props.enqueueSnackbar(("Address copied to clipboard" ), { 
          });
      };

    render() {
        const { open } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.root}>

            { this.props.loggedIn && this.props.account !== null
                    ? (                    
                        <Button 
                            variant="outlined" 
                            color="secondary" 
                            size="small"
                            onClick={this.handleToggle}
                            endIcon={<Blockies scale={3} seed={this.props.account}/>}
                            startIcon=                        { this.props.txPending || this.props.building
                                ? (<CircularProgress size={20} thickness={5} disableShrink={true} color="secondary" />)
                                : (<React.Fragment/>)
                            }
                        >

                        {this.props.account.slice(0,6) + "..." + this.props.account.slice(37,41)}
                        </Button>
                    )
                    : (
                        <Button 
                        variant="outlined" 
                        color="secondary" 
                        onClick={this.handleToggle}
                        size="small"
                        >
                        {"Connect to a Wallet"}
                        </Button>
                        )
            }
            <Dialog 
                classes={{
                    paperScrollPaper: classes.paperScrollPaper,
                    paperScrollBody: classes.paperScrollBody,
                }}
                TransitionComponent={Transition} 
                open={open} 
                onClose={this.handleToggle} 
                aria-labelledby="dialog-connect-to-wallet"
                fullWidth={true}
                maxWidth='sm'
            >
          <Grid 
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
            <Grid item >
                <DialogTitle 
                    align="left" 
                    id="dialog-connect-to-wallet" 
                    classes={{root: classes.dialogTitle}}
                >
                    {"Account"}
                </DialogTitle>
            </Grid>
            <Grid item >
                <IconButton aria-label="close" color="secondary" onClick={this.handleToggle}>
                    <CloseIcon />
                </IconButton>
            </Grid>
            </Grid>
          <Divider classes={{
                root: classes.divider
            }}/>
            <br/><br/>
        <DialogContent className={classes.dialogTextBox}>
            <Grid 
                container
                direction="column"
                spacing={3}
                alignItems="stretch"
            >
                <Grid 
                    xs={12} 
                    item 
                >
                    <Paper className={classes.box} elevation={0}>
                    <Grid 
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                    >
                        <Grid item >
                            <Blockies scale={5} seed={this.props.account}/>
                        </Grid>
                        <Grid item >
                            <Grid 
                                container
                                direction="column"
                                justify="space-between"
                                alignItems="flex-start"
                            >
                                <Grid item>
                                    <Typography color="secondary" variant="button" display="block" >
                                        {"MetaMask"}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color="secondary" variant="button" display="block">
                                        {this.props.account}
                                        <Tooltip title="Etherscan">
                                        <IconButton color="secondary" size="small" href={this.state.urlAccount} rel="noopener" target="_blank" >
                                            <Launch/>
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Copy Address">
                                    <CopyToClipboard text={this.state.account}
                                        onCopy={() => this.setState({copied: true})}>
                                    <IconButton color="secondary" size="small" onClick={this.copyToClipboard}>
                                        <Copy/>
                                    </IconButton>
                                    </CopyToClipboard>
                                    </Tooltip>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {this.props.building
                            ? (
                                <Tooltip placement="top" title={this.props.loadingStatus}>
                                <StyledBadgeRed
                                    component="div"
                                    overlap="circle"
                                    anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                    }}
                                    variant="dot"
                                >
                                    <div className={classes.shape}/>
                                </StyledBadgeRed>
                                </Tooltip>
                            )
                            : this.props.detectedNetwork !== this.props.deployedNetwork
                                ? (
                                    <Tooltip placement="top" title={"Wrong Network: Please switch to " + this.props.deployedNetworkName}>
                                    <StyledBadgeRed
                                        component="div"
                                        overlap="circle"
                                        anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                        }}
                                        variant="dot"
                                    >
                                        <div className={classes.shape}/>
                                    </StyledBadgeRed>
                                    </Tooltip>
                                )
                                : (
                                    <Tooltip placement="top" title={"Connected"}>
                                    <StyledBadgeGreen
                                        component="div"
                                        overlap="circle"
                                        anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                        }}
                                        variant="dot"
                                    >
                                        <div className={classes.shape}/>
                                    </StyledBadgeGreen>
                                    </Tooltip>
                                )
                            }

                        </Grid>
                    </Grid>
                    </Paper>
                </Grid>

            </Grid>
        </DialogContent>

        {/* <DialogContent className={classes.dialogContextBox}>
            <RecentTransactionsPaper 
                handleToggle={this.handleToggle} 
                recentAccountTXarray={this.props.recentAccountTXarray}
            />
        </DialogContent> */}

      </Dialog>
            
            </div>
          );
    }
}

export default (withStyles(styles))(withTheme((withSnackbar(DialogAddressInfo))));