import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DialogueUnGrid from '../Components/DialogueUnGrid';
import AboutDialogue from '../Dialogues/AboutDialogue.json'
import mzkzUke from '../Media/Images/MZKZ-UKE.jpg'

const styles = theme => ({
   root: {
       "textAlign": "center",
       padding: theme.spacing(3),
   },
   aboutText: {
      color: "#CEC075",
      "textAlign": "block",
   },
   image: {
      "max-width": "100%"
   }
 });
 
 class About extends React.Component {

   componentDidMount() {
      window.scrollTo(0, 0);
   }
    
    render() {
      const {classes} = this.props

      return (
         <div className={classes.root} >
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            spacing={2}
          >
            <h1>About</h1>
            <Grid item xs={4}>
               <Typography variant={"h6"} className={classes.aboutText}>
                  {"MZKZ (aka miezekatze) has been involved in the crypto space since 2012. He specializes in crypto puzzles, creates various 2D/3D artwork, builds fun little games, and messes with smart contracts and NFTs. Most of all, he enjoys being a part of the thriving crypto community, sharing his enthusiasm with like-minded people throughout the world."}
               </Typography>
            </Grid>
            <Grid item xs={12}>
               <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="stretch"
                  spacing={2}
               >
                  <Grid item xs={3}/>
                  <Grid item xs={6}>
                     <img className={classes.image} src={mzkzUke} alt={"MZKZ-Ukulele"}/>
                  </Grid>
                  <Grid item xs={3}>
                     <DialogueUnGrid dialogue={AboutDialogue} />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
         </div>
      );

   }
}
 
export default withStyles(styles)(withSnackbar(withTheme(About)));