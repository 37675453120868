import React from "react";
import { ethers } from "ethers";
import date from 'date-and-time';
import {withSnackbar} from 'notistack';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DescriptionPanel from "../Components/DescriptionPanel";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField'
import { Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Speaker from '../Components/Speaker';
import miezeProfile from "../Media/Images/miezeProfile.png"
import katzeProfile from "../Media/Images/katzeProfile.png"

const styles = theme => ({
   root: {
      // display: "flex",
      padding: theme.spacing(3),
   },
   video: {
    "max-width": "100%"
   },
   image: {
    "max-width": "100%"
    },
   description: {
    "max-width": "100%"
   },
   divider: {
    'background-color': theme.palette.secondary.main,
    },
   speaker: {
    "max-width": "100%"
   },
   mintButton: {
    "text-align": "center"
   },
   buttonPaper: {
    padding: theme.spacing(2),
    background:theme.palette.primary.main,
   },
   menuItem: {
    color: "black",
    //    color: theme.palette.secondary.main,
    //    background:theme.palette.primary.main,
   },
   select: {
    color: theme.palette.secondary.main,
    '& .MuiInput-underline:hover:before': {
        borderBottomColor: theme.palette.secondary.main, // Solid underline on hover
      },
   },
   metaDataText: {
    "font-family": "Permanent Marker",
    color: theme.palette.secondary.main,
   },
   headingText: {
    "font-family": "Permanent Marker",
    color: "white",
   },
   labelText: {
    "font-family": "Permanent Marker",
   },
   inputTextField: {
    "& label.MuiInputLabel-root": {
        "font-family": "Permanent Marker",
      },
      '& .MuiInputBase-input': {
        color: theme.palette.secondary.main, // Text color
      },
    //   '& .MuiInput-underline:before': {
    //     borderBottomColor: '#fff8', // Semi-transparent underline
    //   },
      '& .MuiInput-underline:hover:before': {
        borderBottomColor: theme.palette.secondary.main, // Solid underline on hover
      },
    //   '& .MuiInput-underline:after': {
    //     borderBottomColor: '#fff', // Solid underline on focus
    //   },
   },
});
 
const AssetPage = (props) => {
    const [speakerToShow, setSpeakerToShow] = React.useState(0);
    const [amount, setAmount] = React.useState(1);
    const [amount2, setAmount2] = React.useState(1);
    const [Id, setId] = React.useState('');
    const [Id2, setId2] = React.useState('');
    const [Id3, setId3] = React.useState('');
   
    const {
        classes,
        metaData,
        mint,
        mintWhitelist,
        loggedIn,
        isLoading,
        isLoadingCheckClaim,
        mintPresale,
        checkClaim,
        mintClaim,
        mintClaims,
        getBalances,
        getContractData,
        getWhitelistData,
        deployedNetwork,
        deployedNetworkName,
        accountGenesisIds,
        unclaimedAccountGenesisIds,
        unclaimedAccountGenesisIdsForCarvest,
        specificTokenClaims
    } = props;

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getContractData()
        getWhitelistData()
        getBalances()
      }, [getContractData, getWhitelistData, getBalances])
    
    const mintsRemaining = parseInt(metaData.maxSupply) - parseInt(metaData.minted) - parseInt(metaData.reservedSupply) + parseInt(metaData.reserveMinted)
    let maxBuys = metaData.buyLimit

    if (mintsRemaining < metaData.buyLimit) {
        maxBuys = mintsRemaining
    }

    let mintArray = [...Array(parseInt(maxBuys)).keys()]
    mintArray = mintArray.map(a => a + 1);

    let mintArray2 = [...Array(parseInt(metaData.accountReservationAmount)).keys()]
    mintArray2 = mintArray2.map(a => a + 1);
     
    const setSpeaker = (num) => {
    setTimeout(() => {
        setSpeakerToShow(num);
    }, 300);
    };

    const onClick = (amount) => {
        mint(metaData.mintingContract, metaData.salePrice, amount)
    }

    const onClick2 = (amount) => {
        mintWhitelist(metaData.mintingContract, metaData.salePrice, amount)
    }

    const onClick3 = (id) => {
        mintPresale(metaData.mintingContract, metaData.salePrice, metaData.presaleBuyLimitPerQualifyingId, id)
    }

    const onClick4 = (id) => {
        mintClaim(metaData.mintingContract, id)
    }

    const onClick5 = (ids) => {
        mintClaims(metaData.mintingContract, ids)
    }

    const onClick6 = (id) => {
        checkClaim(metaData.mintingContract, id)
    }

    const handleChange = (event) => {
        setAmount(event.target.value);
    };

    const handleChange2 = (event) => {
        setAmount2(event.target.value);
    };

    const handleChange3 = (event) => {
        setId(event.target.value);
    };

    const handleChange4 = (event) => {
        setId2(event.target.value);
    };

    const handleChange5 = (event) => {
        if (event.target.value < 1) {
            event.target.value = 1
        }
        if (event.target.value > 200) {
            event.target.value = 200
        }
        setId3(event.target.value);
    };

    const saleStart = new Date(metaData.saleStart)
    var presaleStartTime = Date.now
    var claimStartTime = Date.now
    if (metaData.presale) {
        presaleStartTime = new Date(metaData.presaleStartTime)
    } else if (metaData.claims) {
        claimStartTime = new Date(metaData.claimStartTime)
    }

    return (
        <div className={classes.root}>
        <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <h1>{metaData.name}</h1>

            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="stretch"
                spacing={2}
            >
                <Grid item xs>
                {metaData.chat.map((text, index) => {
                    if (index <= speakerToShow) {
                        return(
                        <Speaker 
                            className={classes.speaker}
                            text={text} 
                            speakerDirection={index % 2 === Number(metaData.firstSpeaker) ? "Left" : "Right"} 
                            speakerImage={index % 2 === Number(metaData.firstSpeaker) ? miezeProfile : katzeProfile}
                            speakerName={index % 2 === Number(metaData.firstSpeaker) ? "Mieze" : "Katze"} 
                            onFinished={() => setSpeaker(index+1)}
                            key={index}
                        /> 
                        )
                    } else {
                        return <React.Fragment key={index}/>
                    }
                })}
                </Grid>

                <Grid item xs={4}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        spacing={2}
                    >
                        <Grid item>
                            {metaData.mediaType === "video"
                                ? (
                                    <video className={classes.video} autoPlay="autoplay" loop>
                                        <source src={require('../Media/Video/' + metaData.media + '.mp4').default} type="video/mp4"/>
                                    </video>
                                )
                                : (
                                    <img className={classes.image} src={require('../Media/Images/' + metaData.media + '.png').default} alt={`${metaData.slug}-featured-media `}/>
                                )
                            }
                        </Grid>
                        {metaData.whitelist && metaData.saleStatus === "active"
                        ? (
                            <Grid className={classes.mintButton} item>
                            <Paper className={classes.buttonPaper}>
                            <Typography variant="h4" className={classes.headingText}>
                                {`Whitelist`}
                            </Typography>
                            <Divider classes={{root: classes.divider}}/>
                            <br/>
                            {!loggedIn
                                ? (
                                    <Typography variant="h5" className={classes.metaDataText}>
                                        {`Connect a Wallet to check Reservation`}
                                    </Typography>
                                )
                                : isLoading 
                                    ? <CircularProgress size={20} thickness={5} disableShrink={true} color="secondary" />
                                    : metaData.accountReservationAmount > 0
                                    ? (
                                        <Grid
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="flex-end"
                                            spacing={1}
                                        >
                                            <Grid item xs={2}>
                                                <FormControl className={classes.select} color="secondary" fullWidth>
                                                    <InputLabel className={classes.labelText} color="secondary" id="mint-select-label">Amount</InputLabel>
                                                    <Select
                                                        className={classes.select}
                                                        labelId="mint-select-label"
                                                        id="mint-select"
                                                        value={amount2}
                                                        label="Amount"
                                                        onChange={handleChange2}
                                                        
                                                    >
                                                    {mintArray2.map((value, index) => {
                                                        return <MenuItem className={classes.menuItem} value={value} key={index}>{value}</MenuItem>
                                                    })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="h5" className={classes.metaDataText}>
                                                {` ${ethers.utils.formatEther(ethers.utils.parseEther(metaData.salePrice).mul(amount2))} ETH`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button className={classes.labelText} color='secondary' variant='contained' disabled= {(!loggedIn || Date.now() < new Date(metaData.saleStart) || metaData.accountReservationAmount < 1) ? true : false} onClick={() => onClick2(amount2)}>
                                                    {Date.now() < new Date(metaData.saleStart) ? `Dropping Soon` : "Mint Reserved!"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )
                                    : (
                                        <Typography variant="h5" className={classes.metaDataText}>
                                            {`No Reservations for this account. Try Public minting.`}
                                        </Typography>
                                    )
                            }
                            </Paper>
                        </Grid>
                        )
                        : null
                        }
                        {metaData.presale && metaData.saleStatus !== "upcoming"
                        ? (
                            <Grid className={classes.mintButton} item>
                            <Paper className={classes.buttonPaper}>
                            <Typography variant="h4" className={classes.headingText}>
                                {`Presale`}
                            </Typography>
                            <Divider classes={{root: classes.divider}}/>
                            <br/>
                            {!loggedIn
                                ? (
                                    <Typography variant="h5" className={classes.metaDataText}>
                                        {`Connect a Wallet to check Priority Mint Allocation`}
                                    </Typography>
                                )
                                : isLoading 
                                    ? <CircularProgress size={20} thickness={5} disableShrink={true} color="secondary" />
                                    : unclaimedAccountGenesisIds.length > 0
                                    ? (
                                        <React.Fragment>
                                        <Typography className={classes.headingText}>
                                            {`Select a qualifying token to mint during the presale.`}
                                        </Typography>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="flex-end"
                                            spacing={1}
                                        >
                                            <Grid item xs={3}>
                                                <FormControl className={classes.select} color="secondary" fullWidth>
                                                    <InputLabel className={classes.labelText} color="secondary" id="presale-mint-select-label">Token</InputLabel>
                                                    <Select
                                                        className={classes.select}
                                                        labelId="presale-mint-select-label"
                                                        id="presale-mint-select"
                                                        value={Id}
                                                        label="Token"
                                                        onChange={handleChange3}
                                                        
                                                    >
                                                    {unclaimedAccountGenesisIds.map((id, index) => {
                                                        return <MenuItem className={classes.menuItem} value={id} key={index}>{`The Ambush #${id}`}</MenuItem>
                                                    })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="h5" className={classes.metaDataText}>
                                                {` ${ethers.utils.formatEther(ethers.utils.parseEther(metaData.salePrice).mul(metaData.presaleBuyLimitPerQualifyingId))} ETH`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Tooltip title={`Presale begins ${date.format(presaleStartTime, "MM/DD/YYYY h:mm A")} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`}>
                                                <span>
                                                    <Button className={classes.labelText} color='secondary' variant='contained' disabled= {(!loggedIn || Date.now() < new Date(metaData.presaleStartTime) || unclaimedAccountGenesisIds.length < 1) ? true : false} onClick={() => onClick3(Id)}>
                                                        {Date.now() < new Date(metaData.presaleStartTime) ? `Dropping Soon` : "Mint Priority!"}
                                                    </Button>
                                                </span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        </React.Fragment>
                                    )
                                    : accountGenesisIds.length > 0
                                    ? (
                                        <Typography variant="h5" className={classes.metaDataText}>
                                            {`Account has no qualifying presale tokens.`}
                                        </Typography>
                                    )
                                    :(
                                        <Typography variant="h5" className={classes.metaDataText}>
                                            {`Account does not hold The Ambush. Try Public minting.`}
                                        </Typography>
                                    )
                            }
                            </Paper>
                        </Grid>
                        )
                        : null
                        }
                        {metaData.claims && metaData.saleStatus !== "upcoming"
                        ? (
                            <Grid className={classes.mintButton} item>
                            <Paper className={classes.buttonPaper}>
                            <Typography variant="h4" className={classes.headingText}>
                                {`Claim`}
                            </Typography>
                            <Divider classes={{root: classes.divider}}/>
                            <br/>
                            {!loggedIn
                                ? (
                                    <Typography variant="h5" className={classes.metaDataText}>
                                        {`Connect a Wallet to check your Free Claim Allocation`}
                                    </Typography>
                                )
                                : isLoading 
                                    ? <CircularProgress size={20} thickness={5} disableShrink={true} color="secondary" />
                                    : unclaimedAccountGenesisIdsForCarvest.length > 0
                                    ? (
                                        <React.Fragment>
                                        <Typography className={classes.headingText}>
                                            {`Select a qualifying token to mint a free claim or mint all claims in one transaction.`}
                                        </Typography>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="flex-end"
                                            spacing={1}
                                        >
                                            <Grid item xs={3}>
                                                <FormControl className={classes.select} color="secondary" fullWidth>
                                                    <InputLabel className={classes.labelText} color="secondary" id="claim-mint-select-label">Token</InputLabel>
                                                    <Select
                                                        className={classes.select}
                                                        labelId="claim-mint-select-label"
                                                        id="claim-mint-select"
                                                        value={Id2}
                                                        label="Token"
                                                        onChange={handleChange4}
                                                        
                                                    >
                                                    {unclaimedAccountGenesisIdsForCarvest.map((id, index) => {
                                                        let assetName = ""
                                                        let printId = id
                                                        if (id > 0 && id <= 100) {
                                                            assetName = "The Ambush"
                                                        } else if (id > 100 && id <= 200) {
                                                            assetName = "Stripped Sour"
                                                            printId = id - 100
                                                        }
                                                        return <MenuItem className={classes.menuItem} value={id} key={index}>{`${assetName} #${printId}`}</MenuItem>
                                                    })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="h5" className={classes.metaDataText}>
                                                {` FREE + gas`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Tooltip title={`Claims begin ${date.format(claimStartTime, "MM/DD/YYYY h:mm A")} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`}>
                                                <span>
                                                    <Button className={classes.labelText} color='secondary' variant='contained' disabled= {(!loggedIn || Date.now() < new Date(metaData.claimStartTime) || unclaimedAccountGenesisIdsForCarvest.length < 1) ? true : false} onClick={() => onClick4(Id2)}>
                                                        {Date.now() < new Date(metaData.claimStartTime) ? `Dropping Soon` : "Claim"}
                                                    </Button>
                                                </span>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Tooltip title={`Claims begin ${date.format(claimStartTime, "MM/DD/YYYY h:mm A")} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`}>
                                                <span>
                                                    <Button className={classes.labelText} color='secondary' variant='contained' disabled= {(!loggedIn || Date.now() < new Date(metaData.claimStartTime) || unclaimedAccountGenesisIdsForCarvest.length < 1) ? true : false} onClick={() => onClick5(unclaimedAccountGenesisIdsForCarvest)}>
                                                        {Date.now() < new Date(metaData.claimStartTime) ? `Dropping Soon` : `Claim ${unclaimedAccountGenesisIdsForCarvest.length}`}
                                                    </Button>
                                                </span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        </React.Fragment>
                                    )
                                    : accountGenesisIds.length > 0
                                    ? (
                                        <Typography variant="h5" className={classes.metaDataText}>
                                            {`Account has no qualifying claim tokens.`}
                                        </Typography>
                                    )
                                    :(
                                        <Typography variant="h5" className={classes.metaDataText}>
                                            {`Account does not hold any MZKZ NFTs. Try Public minting.`}
                                        </Typography>
                                    )
                            }
                            </Paper>
                        </Grid>
                        )
                        : null
                        }
                        {metaData.claims && metaData.saleStatus !== "upcoming"
                        ? (
                            <Grid className={classes.mintButton} item>
                            <Paper className={classes.buttonPaper}>
                            <Typography variant="h4" className={classes.headingText}>
                                {`Check Claim`}
                            </Typography>
                            <Divider classes={{root: classes.divider}}/>
                            <br/>
                            {!loggedIn
                                ? (
                                    <Typography variant="h5" className={classes.metaDataText}>
                                        {`Connect a Wallet to check the claim status of a token`}
                                    </Typography>
                                )
                                : isLoading 
                                    ? <CircularProgress size={20} thickness={5} disableShrink={true} color="secondary" />
                                    : (
                                        <React.Fragment>
                                        <Typography className={classes.headingText}>
                                            {`Enter a token ID to check its claim status.`}
                                        </Typography>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="flex-end"
                                            spacing={1}
                                        >
                                            <Grid item xs={3}>
                                                <TextField 
                                                    className={classes.inputTextField}
                                                    labelId="check-claim-input-label"
                                                    id="check-claim-input"
                                                    value={Id3}
                                                    label="Token"
                                                    type="number"
                                                    color="secondary"
                                                    onChange={handleChange5}
                                                    
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                {isLoadingCheckClaim
                                                   ? <CircularProgress size={20} thickness={5} disableShrink={true} color="secondary" />
                                                   : (
                                                        <Typography variant="h5" className={classes.metaDataText}>
                                                            {specificTokenClaims > 0 ? "Claimed": "Unclaimed"}
                                                        </Typography>
                                                   )
                                                }
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Tooltip title={`Claims begin ${date.format(claimStartTime, "MM/DD/YYYY h:mm A")} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`}>
                                                <span>
                                                    <Button className={classes.labelText} color='secondary' variant='contained' disabled= {(!loggedIn) ? true : false} onClick={() => onClick6(Id3)}>
                                                        {"Check"}
                                                    </Button>
                                                </span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        </React.Fragment>
                                    )
                            }
                            </Paper>
                        </Grid>
                        )
                        : null
                        }
                        <Grid className={classes.mintButton} item>
                            <Paper className={classes.buttonPaper}>
                            <Typography variant="h4" className={classes.headingText}>
                                {`Public Sale`}
                            </Typography>
                            <Divider classes={{root: classes.divider}}/>
                            <br/>
                            {!loggedIn
                                ? (
                                    <Typography variant="h5" className={classes.metaDataText}>
                                        {`Connect a Wallet to Mint`}
                                    </Typography>
                                )
                                : isLoading 
                                    ? <CircularProgress size={20} thickness={5} disableShrink={true} color="secondary" />
                                    : mintsRemaining > 0
                                    ? (
                                        <Grid
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="flex-end"
                                            spacing={1}
                                        >
                                            <Grid item xs={2}>
                                                <FormControl className={classes.select} color="secondary" fullWidth>
                                                    <InputLabel className={classes.labelText} color="secondary" id="mint-select-label">Amount</InputLabel>
                                                    <Select
                                                        className={classes.select}
                                                        labelId="mint-select-label"
                                                        id="mint-select"
                                                        value={amount}
                                                        label="Amount"
                                                        onChange={handleChange}
                                                        
                                                    >
                                                    {mintArray.map((value, index) => {
                                                        return <MenuItem className={classes.menuItem} value={value} key={index}>{value}</MenuItem>
                                                    })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="h5" className={classes.metaDataText}>
                                                {` ${ethers.utils.formatEther(ethers.utils.parseEther(metaData.salePrice).mul(amount))} ETH`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Tooltip title={Date.now() < new Date(metaData.saleStart) ? `Sale begins ${date.format(saleStart, "MM/DD/YYYY h:mm A")} ${Intl.DateTimeFormat().resolvedOptions().timeZone}` : mintsRemaining > 1 ? `Sale is active` : `Sale is inactive`}>
                                                    <span>
                                                    <Button className={classes.labelText} color='secondary' variant='contained' disabled= {(!loggedIn || Date.now() < new Date(metaData.saleStart) || mintsRemaining < 1) ? true : false} onClick={() => onClick(amount)}>
                                                        {Date.now() < new Date(metaData.saleStart) ? `Dropping Soon` : "Mint"}
                                                    </Button>
                                                    </span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    )
                                    : (
                                        <Typography variant="h5" className={classes.metaDataText}>
                                            {metaData.saleStatus === "upcoming" ? `Dropping Soon` : `Sold Out`}
                                        </Typography>
                                    )
                            }
                            </Paper>
                        </Grid>
                        <Grid item>
                            <DescriptionPanel 
                                className={classes.description} 
                                metaData={metaData} 
                                isLoading={isLoading} 
                                loggedIn={loggedIn}
                                deployedNetwork={deployedNetwork}
                                deployedNetworkName={deployedNetworkName}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs> </Grid>
            </Grid>

        </Grid>
        </div>
    );
}
 
export default withStyles(styles)(withSnackbar(withTheme(AssetPage)));