import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Chat from './WelcomeWindup';

const styles = theme => ({
    root: {
    //   display: 'flex',
      // flexDirection: 'column',
      "min-height": '100vh',
      position: "relative",
      "padding-bottom": "60px",
    },
    avatar: {
        animation: "$fadeIn 500ms",
        "max-width": '100%',
    },
    "@keyframes fadeIn": {
        from: {
          opacity: "0"
        },
        to: {
          opacity: "1"
        }
      }
  });
 
const Speaker = (props) => {   
      
    const {
        classes,
        speakerDirection,
        speakerImage,
        speakerName,
        text,
        onFinished
    } = props;

      return (
        <div >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify={speakerDirection === "Left" ? "flex-start" : "flex-end"}
                alignItems="flex-start"
                spacing={2}
              >
              {speakerDirection === "Left" ? 
                (
                    <React.Fragment>
                        <Grid item xs={3}>
                        <img  className={classes.avatar} style={speakerDirection === "Left" ? {float:"right"} : {float:"left"}} src={speakerImage} alt={speakerName} />
                        </Grid>
                        <Grid item xs={9}>
                        <Chat text={text} speakerDirection={speakerDirection} onFinished={onFinished}/>
                        </Grid>
                    </React.Fragment>
                ) :
                (
                    <React.Fragment>
                        <Grid item xs={9}>
                            <Chat text={text} speakerDirection={speakerDirection} onFinished={onFinished}/>
                        </Grid>
                        <Grid item xs={3}>
                            <img  className={classes.avatar} style={speakerDirection === "Left" ? {float:"right"} : {float:"left"}} src={speakerImage} alt={speakerName} />
                        </Grid>
                    </React.Fragment>
                )
              }
              </Grid>
            </Grid>
        </div>
       );

}
 
export default withStyles(styles)(Speaker)