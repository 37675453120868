import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import { withSnackbar } from 'notistack';
import DialogueGrid from '../Components/DialogueGrid';
import Banner from '../Media/Images/MZKZlogo.svg'
import introduction from '../Dialogues/Introduction'

 
const styles = theme => ({
    root: {
        // display: 'flex'
        "text-align": "cneter",
        padding: theme.spacing(3),
    }
  });

class Home extends React.Component {
    
    componentDidMount() {
        window.scrollTo(0, 0)
      }

   render() {
    const {classes} = this.props;
      return (
        <div className={classes.root}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={2}
          >
          <img src={Banner} style={{ "textAlign": 'center' }} alt="Logo"/>
          </Grid>
          <br/><br/><br/>
            <DialogueGrid dialogue={introduction} callToAction={"artworks"}/>
            
        </div>
       );
   }

}
 
export default withStyles(styles)(withSnackbar(withTheme(Home)));